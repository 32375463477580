<template>
  <div class="new-project-modal" :class="{ regie: isFromRegie }">
    <div class="close" @click="handleClose">
      <font-awesome-icon icon="times" />
    </div>
    <div v-if="isFromRegie" class="new-project-modal-wrapper">
      <div class="server-error" v-if="getProjectsCreateError">
        <div v-if="getProjectsCreateError" class="error">
          <ul v-if="Array.isArray(getProjectsCreateError)">
            <li
              v-for="(e, index) in getProjectsCreateError"
              :key="'get-projects-create-error-' + index"
            >
              {{ e }}
            </li>
          </ul>
          <span v-else>{{ getProjectsCreateError }}</span>
        </div>
      </div>
      <RegieForm
        :enableFastForm="fastFormEnabled"
        @created="handleRegieFormCreated"
        :currentType="currentType"
      />
    </div>
    <div v-else class="new-project-modal-wrapper">
      <h2>
        Nouveau projet
      </h2>
      <div class="server-error" v-if="getProjectsCreateError">
        <div v-if="getProjectsCreateError" class="error">
          <ul v-if="Array.isArray(getProjectsCreateError)">
            <li
              v-for="(e, index) in getProjectsCreateError"
              :key="'get-projects-create-error-' + index"
            >
              {{ e }}
            </li>
          </ul>
          <span v-else>{{ getProjectsCreateError }}</span>
        </div>
      </div>

      <form @submit.prevent="addProject">
        <div class="description-bloc">
          1. Choisir le type de projet
        </div>
        <b-form-group
          class="mt-2"
          label="Type de projet :"
          label-for="type-input"
        >
          <b-form-select
            class="mt-2"
            id="type-input"
            :options="visibilityProjectsTypes"
            value-field="id"
            text-field="name"
            v-model="projectToAdd.type"
            :required="true"
          ></b-form-select>
        </b-form-group>
        <div class="description-bloc" :class="{ error: contactRequiredError }">
          2. Choisir un contact existant ou créer un nouveau contact
        </div>
        <b-form-group class="mt-2" label="Contact :">
          <b-form-radio v-model="contactState" value="old">
            Contact existant
          </b-form-radio>
          <b-form-radio v-model="contactState" value="new">
            Nouveau contact
          </b-form-radio>
        </b-form-group>
        <b-form-group label-for="contact-input" v-if="contactState == 'old'">
          <Multiselect
            class="mt-2"
            v-model="selectedContact"
            label="full_name"
            track-by="id"
            :showLabels="false"
            :closeOnSelect="true"
            open-direction="bottom"
            :options="getContactsList"
            :searchable="true"
            :loading="getContactsProcessing"
            :internal-search="false"
            :max-height="600"
            placeholder="Rechercher un contact"
            @search-change="asyncSearchContact"
            :required="true"
          >
            <span slot="noResult">Aucun contact trouvé.</span>
            <span slot="noOptions">Aucun contact trouvé.</span>
          </Multiselect>
        </b-form-group>
        <template v-else>
          <b-form-group label="Nom" class="mt-2" label-for="first-name-input">
            <b-form-input
              class="mt-2"
              id="first-name-input"
              v-model="contactToAdd.last_name"
              :required="true"
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Prénom" class="mt-2" label-for="last-name-input">
            <b-form-input
              class="mt-2"
              id="last-name-input"
              v-model="contactToAdd.first_name"
              :required="true"
            ></b-form-input>
          </b-form-group>
        </template>
        <!-- Add Deposit Modal: buttons -->
        <div class="form-actions mt-4">
          <b-button
            @click="handleClose"
            variant="outline-secondary"
            :disabled="getProjectsProcessing"
          >
            Annuler
          </b-button>
          <b-button
            variant="success"
            type="submit"
            :disabled="getProjectsProcessing"
          >
            Enregistrer
          </b-button>
        </div>
      </form>
    </div>
    <!-- Confirmation create nouvelle projet existe  -->
    <b-modal
      no-close-on-backdrop
      ref="show-list-project"
      id="show-list-project"
      title="Liste des projets de contact existant"
      :hide-footer="true"
      scrollable
    >
      <div class="page-content margin-top">
        <label
          >Liste des projets de contact
          <span class="bold uppercase">
            {{
              `${this.contactToAdd.last_name} ${this.contactToAdd.first_name}`
            }}
          </span>
          :
        </label>

        <div
          class="tabs flex"
          v-for="(projet, index) in getcheckDoubleProject"
          :key="index"
        >
          <div class="tab-button-contact bold border-right border-bottom">
            {{ typeProject }}
          </div>

          <router-link
            :to="'/projects/' + projet.id"
            target="_blank"
            class="link border-right border-bottom"
            :class="{ 'border-top': index === 0 }"
          >
            <span class="margin">{{ projet.contact_full_name }}</span>
          </router-link>
        </div>
      </div>
      <div class="message mt-1">
        <div
          v-if="getProjectsProcessing || getContactsProcessing"
          class="three-dots-loading"
        >
          Chargement en cours
        </div>
        <div v-if="getProjectsError" class="error">
          <ul v-if="Array.isArray(getProjectsError)">
            <li v-for="(e, index) in getProjectsError" :key="index">
              {{ e }}
            </li>
          </ul>
          <span v-else>{{ getProjectsError }}</span>
        </div>
      </div>
      <div class="form-actions top">
        <b-button
          @click="hideModal('show-list-project')"
          variant="outline-secondary"
        >
          Annuler
        </b-button>
        <b-button @click="createDoubleProject" variant="success">
          Ajouter quand même le nouveau projet
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { mapActions, mapGetters } from 'vuex'
import { debounce } from 'lodash'

export default {
  name: 'new-project-modal',
  components: {
    Multiselect,
    RegieForm: () => import('./RegieForm.vue')
  },
  props: {
    currentType: {
      required: true
    }
  },
  data() {
    return {
      projectToAdd: {},
      contactToAdd: {},
      selectedContact: null,
      contactRequiredError: false,
      contactState: 'old',
      typeProject: null
    }
  },
  methods: {
    ...mapActions([
      'createProject',
      'searchContacts',
      'resetSearchContacts',
      'checkDoubleProject'
    ]),
    async addProject() {
      this.contactRequiredError = false
      if (this.contactState == 'old' && !this.selectedContact) {
        this.contactRequiredError = true
        return
      }
      const data = {}
      data.dtype_id = this.projectToAdd.type
      this.getPipelinesPipelines.forEach(pipeline => {
        if (pipeline.type.id == data.dtype_id && pipeline.is_principal == 1) {
          data.cpipeline_id = pipeline.id
          if (pipeline.stages && pipeline.stages[0]) {
            data.cstage_id = pipeline.stages[0].id
          }
          if (pipeline.stages[0].subStages && pipeline.stages[0].subStages[0]) {
            data.csubstage_id = pipeline.stages[0].subStages[0].id
          }
        }
      })

      if (this.contactState == 'old') {
        data.contact_id = this.selectedContact.id
        data.title = this.selectedContact.full_name
        const response = await this.createProject({
          currentType: this.currentType,
          data
        })
        if (response) {
          this.resetNewProject()
          this.$emit('created', this.getProjectsLastCreated)
        }
      } else {
        this.contactToAdd.type_id = this.projectToAdd.type
        data.contact = this.contactToAdd
        data.title = `${this.contactToAdd.first_name} ${this.contactToAdd.last_name}`
        const response = await this.checkDoubleProject(data.contact)
        if (response) {
          if (
            this.getcheckDoubleProject &&
            this.getcheckDoubleProject.length &&
            this.getcheckDoubleProject.length >= 1
          ) {
            this.typeProject = this.visibilityProjectsTypes.find(
              item => item.id == this.contactToAdd.type_id
            ).name
            this.$refs['show-list-project'].show()
          } else {
            const response_1 = await this.createProject({
              currentType: this.currentType,
              data
            })
            if (response_1) {
              this.resetNewProject()
              this.$emit('created', this.getProjectsLastCreated)
              this.hideModal('show-list-project')
            }
          }
        }
      }
    },
    async createDoubleProject() {
      const data = {}
      data.dtype_id = this.projectToAdd.type
      this.getPipelinesPipelines.forEach(pipeline => {
        if (pipeline.type.id == data.dtype_id && pipeline.is_principal == 1) {
          data.cpipeline_id = pipeline.id
          if (pipeline.stages && pipeline.stages[0]) {
            data.cstage_id = pipeline.stages[0].id
          }
          if (pipeline.stages[0].subStages && pipeline.stages[0].subStages[0]) {
            data.csubstage_id = pipeline.stages[0].subStages[0].id
          }
        }
      })
      data.contact = this.contactToAdd
      data.title = `${this.contactToAdd.first_name} ${this.contactToAdd.last_name}`
      const response_1 = await this.createProject({
        currentType: this.currentType,
        data
      })
      if (response_1) {
        this.resetNewProject()
        this.$emit('created', this.getProjectsLastCreated)
        this.hideModal('show-list-project')
      }
    },
    hideModal(ref) {
      if (ref && this.$refs[ref]) {
        this.$refs[ref].hide()
      }
    },
    handleRegieFormCreated() {
      this.$emit('created', this.getProjectsLastCreated)
    },

    resetNewProject() {
      this.projectToAdd = {}
      this.contactToAdd = {}
      this.selectedContact = null
      this.contactRequiredError = false
      this.contactState = 'old'
      this.resetSearchContacts()
    },
    asyncSearchContact: debounce(function(query) {
      if (query && query.length > 1) {
        this.searchContacts(query)
      }
    }, 400),
    handleClose() {
      this.$emit('close')
    }
  },
  computed: {
    ...mapGetters([
      'getPipelinesPipelines',
      'getProjectsCreateError',
      'getProjectsLastCreated',
      'getContactsList',
      'getContactsProcessing',
      'getProjectsProcessing',
      'getProjectsError',
      'getProjectsTypes',
      'currentUser',
      'getcheckDoubleProject'
    ]),
    fastFormEnabled: function() {
      if (this.currentUser.details && this.currentUser.details.see_form_fast) {
        return true
      }
      return false
    },
    isFromRegie: function() {
      if (
        this.currentUser &&
        (this.currentUser.type == 'user.commercial' ||
          this.currentUser.type == 'user.final' ||
          (this.currentUser.type == 'user.admin' &&
            this.currentUser.details &&
            this.currentUser.details.team &&
            this.currentUser.details.team.data &&
            (this.currentUser.details.team.data.type == 'regie' ||
              this.currentUser.details.team.data.type === 'installateur')))
      ) {
        return true
      }
      return false
    },
    visibilityProjectsTypes: function() {
      // if (this.currentUser.team_id == 425) {
      //   // filiale : ATELIER E-LEARNING
      //   return this.getProjectsTypes.filter(type => type.id == 110)
      // } else {
      //   return this.getProjectsTypes.filter(
      //     type => ![46, 103, 105, 107, 109, 112].includes(type.id)
      //   )
      // }
      return this.getProjectsTypes
    }
  }
}
</script>

<style lang="scss" scoped>
.new-project-modal {
  position: fixed;
  height: calc(100% - 48px);
  width: 500px;
  top: 48px;
  right: 0;
  z-index: 101;

  .close {
    position: absolute;
    font-size: 20px;
    top: 10px;
    right: 24px;
    cursor: pointer;
    z-index: 1;
  }

  .new-project-modal-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #fff;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.32);
    padding: 30px;
    font-size: 13px;
    color: #5e5e5e;

    .description-bloc {
      position: relative;
      font-weight: 600;
      padding: 4px 10px;
      background-color: #ebebeb;
      border: 1px solid #d8d8d8;

      &.error {
        background-color: #ff6961;
      }
    }
    .server-error {
      margin-top: 20px;
      color: #ff6961;
    }
  }

  &.regie {
    width: 800px;
    max-width: 100%;
    min-width: 400px;

    .new-project-modal-wrapper {
      overflow-y: auto;
    }
  }
}
#show-list-project {
  .tabs {
    .tab-button-contact {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4px 0;
      width: 47%;
      background-color: #e5e5e5;
      border: 1px solid #d5d5d5;
      color: #5e5e5e;
      font-size: 12px;
      text-align: center;
    }
    .link {
      color: #5e5e5e;
      display: flex;
      align-items: center;
      padding: 4px 0;
      width: 100%;
      background-color: #ffffff;
      color: #5e5e5e;
      cursor: pointer;

      &:hover {
        color: #5e5e5e;
        background-color: transparent;
        text-decoration: none;
        font-weight: bold;
      }
      .margin {
        margin-left: 5px;
      }
    }
  }
  .flex {
    display: flex;
  }
  .uppercase {
    text-transform: uppercase;
  }
  .bold {
    font-weight: bold;
    font-size: 15px;
  }
  .border-top {
    border-top: 1px solid #dee2e6;
  }
  .top {
    margin-top: 16px;
  }
}
</style>
